<template>
    <div class="resetPassword xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>个人中心</h3>
                    <p>当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>个人中心</a>
                    </p>
                </div>
            </div>
        </div>
        <div class="personal-content">
            <div class="personal-box clearfix">
                <div class="personal-left">
                    <hd-img></hd-img>
                </div>
                <div class="personal-right">
                    <div class="personal-right-tit patientheard">
                        <div><i class="iconfont">&#xe62c;</i><span>交易<em>记录</em></span></div>
                        <div class="patientright">
                            <!-- <el-input v-model="keyWord" style="width: 100%" placeholder="请输入内容">
                                <el-button slot="append" class="search-btn" type="button" icon="el-icon-search" @click="search"></el-button>
                            </el-input> -->
                            <!-- <div style="color:#1FB7A6;font-weight: bold;margin-left: 15px;cursor: pointer;" @click="goadd"><i class="el-icon-plus"></i>发布</div> -->
                        </div>
                    </div>
                    <div class="center-content">
                        <el-table :data="tableData" style="width: 100%" :show-header="true" >
                            <el-table-column prop="orderNum" label="单号">
                            </el-table-column>
                            <el-table-column prop="userName" label="用户">
                            </el-table-column>
                            <el-table-column prop="money" label="金额" align="center">
                            </el-table-column>
                            <el-table-column prop="remark" label="备注" align="center">
                            </el-table-column>
                            <el-table-column prop="addTime" label="下单时间" align="right">
                                <template slot-scope="scope">{{ scope.row.addTime | timefilters }}</template>
                            </el-table-column>
                        </el-table>
                        <el-pagination @current-change="ChangePage" :page-size="15" :current-page="PageIndex"
                            :total="CollectTotal" layout="total, prev, pager, next, jumper"></el-pagination>
                    </div>
                </div>
            </div>

        </div>
        <ft-com></ft-com>
</div>
</template>
<script>
import ftCom from '../../components/footer'
import nhCom from "../../components/navheader";
import hdImg from "../../components/headerImg";
import { videoMain } from "../../components/video/video";
export default {
    name: "prescriptList",
    components: {
        ftCom,
        nhCom,
        hdImg
    },
    data() {
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        return {
            videoDomain: video,
            id: this.$route.query.Id,
            keyWord: ' ',
            PageIndex: 1,
            PageTotal: 1,
            CollectTotal: 0,
            tableData: [],
        };
    },
    mounted() {
        this.getList()
    },
    methods: {
        search() { 
            this.PageIndex=1
            this.getList();
        },
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        getList() {
            var _this = this;
            var params = `${_this.id}/${_this.keyWord}/${_this.PageIndex}`
            _this.videoDomain.GetVideoAllOrder(params, function (data) {
                _this.tableData = data.data.results
                _this.PageIndex = data.data.pageIndex;
                _this.PageTotal = data.data.pageCount;
                _this.CollectTotal = data.data.dataTotal;
            }, function (error) {
                // _this.$message.error('修改失败！旧密码不正确，请重新输入。');
            })
        },
    }
}
</script>

<style scoped>
@import "../../assets/css/footer.css";

.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/news_banner.jpg") center no-repeat;
}

.patientheard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.patientright {
    display: flex;
    align-items: center;
}

.patientBox {
    padding: 21px 42px;
    color: #808080
}

.patient {
    padding: 21px 0 15px 0;
    border-bottom: 1px dashed #CCCCCC;
}

.pList {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pListLeft {
    display: flex;
    align-items: center;
}

.banner-wrap {
    position: relative;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
}

.banner-text {
    float: left;
}

.banner-text h3 {
    padding-top: 35px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text p {
    font-size: 14px;
    color: #ffffff;
    margin-top: 20px;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

/**/
.personal-content {
    background: #f7f7f7;
    padding: 40px 0;
}

.personal-box {
    width: 1200px;
    margin: 0 auto;
}

.personal-left {
    float: left;
}

.personal-right {
    float: right;
    width: 900px;
    background: #FFFFFF;
    box-sizing: border-box;
}

.personal-right-tit {
    border-bottom: 1px solid #e5e5e5;
    padding: 20px;
}

.personal-right-tit .iconfont {
    color: #04a290;
    font-size: 24px;
    margin-right: 5px;
}

.personal-right-tit span {
    display: inline-block;
    font-size: 24px;
    font-weight: 700;
    color: #333333;
}

.personal-right-tit span em {
    font-style: normal;
    color: #04a290;
}

.personal-right>form {
    padding: 30px 15px;
    width: 460px;
}</style>
<style>.personal-center {
    display: none;
}</style>
